<template>
  <div>
    <div class="card shadow-sm">
      <div class="card-header">
        <h5 class="my-auto">
          Payments
        </h5>
      </div>
      <div class="card-body">
        <div id="filters" class="row">
          <div class="col-4">
            <label for="">Search</label>
            <form @submit.prevent="search">
              <div class="input-group">
                <div class="input-group-prepend">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="clear"
                  >
                    <i class="bi bi-arrow-clockwise text-dark"></i>
                  </button>
                </div>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="params.column"
                  @change="changeInputType"
                >
                  <option value="a.id">Application No</option>
                  <option value="a.lastname">Last Name</option>
                  <option value="a.firstname">First Name</option>
                  <option value="a.middlename">Middle Name</option>
                  <option value="p.receipt_date">OR Date</option>
                </select>
                <input
                  id="search-input"
                  type="text"
                  class="form-control"
                  placeholder="Search"
                  v-model="params.value"
                />

                <div class="input-group-append">
                  <button
                    id="payment-search-btn"
                    class="btn btn-outline-secondary"
                    type="submit"
                  >
                    <i class="bi bi-search text-dark"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <!---------------------- FILTER DATA --------------------------->

          <div class="col">
            <label for="">Actions</label>
            <div class="form-group">
              <button
                class="btn btn-outline-secondary text-dark mr-3 "
                disabled
              >
                <i class="bi bi-list-ul mr-1"></i> Generate Daily Report (CSV)
              </button>

              <button class="btn btn-outline-secondary text-dark" disabled>
                <i class="bi bi-cloud-arrow-down mr-1"></i> Export All Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow-sm" v-show="payments.length">
      <div class="card-body">
        <table
          class="table table-sm table-bordered table-hover text-center mb-0"
          style="font-size: 12px;"
        >
          <thead class="">
            <th>Payment ID</th>
            <th>App No</th>
            <th>Name</th>
            <th>Date Applied</th>
            <th>CAV No</th>

            <th>OR No</th>
            <th>OR Date</th>
            <th>Amount</th>
            <th>CTC</th>
            <th>Reference No</th>
            <th>Depositor</th>
            <th>Deposited on</th>

            <th>Updated by</th>
          </thead>
          <tbody class="text-uppercase">
            <tr v-for="p in payments" :key="p.id">
              <th>
                <a href="#" @click="openModal(p)">
                  {{ p.id }}
                </a>
              </th>
              <td>{{ p.app_no }}</td>
              <td class="text-left">
                {{ p.firstname }} {{ p.middlename }} {{ p.lastname }}
              </td>
              <td>
                {{ p.date_applied | dateTime }}
              </td>
              <td>{{ p.control_no }}</td>
              <td>{{ p.receipt_no }}</td>
              <td>{{ p.receipt_date | shortDate }}</td>
              <td>&#8369; {{ p.amount }}</td>
              <td>{{ p.ctc_qty }}</td>
              <td>{{ p.reference_no }}</td>
              <td>{{ p.depositor }}</td>
              <td>{{ p.deposited_at | shortDate }}</td>

              <td>{{ p.updated_by }} ({{ p.updated_at | shortDate }})</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Edit Modal -->

    <div
      class="modal fade"
      data-backdrop="static"
      id="edit-payment-details-modal"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-group">
              <label for="">Name</label>
              <h5 class="text-uppercase">
                {{ form.firstname }} {{ form.middlename }}
                {{ form.lastname }}
              </h5>
              <p>
                Application No: <b>{{ form.app_no }}</b>
              </p>
            </div>

            <div class="form-row form-group">
              <div class="col ">
                <label for="">CAV Control No</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="bi bi-file-lock"></i
                    ></span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.control_no"
                    maxlength="6"
                    required
                  />
                </div>
              </div>

              <div class="col">
                <label for="">Amount</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">&#8369; </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.amount"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="">Receipt No</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="bi bi-receipt-cutoff"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.receipt_no"
                      maxlength="7"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="">Date Issued</label>
                  <div class="input-group">
                    <input
                      type="date"
                      class="form-control"
                      v-model="form.receipt_date"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="">Reference/Transaction No/Deposit Slip No</label>
              <input
                type="text"
                class="form-control"
                v-model="form.reference_no"
                required
              />
            </div>

            <div class="form-group">
              <label for="">Name of Depositor</label>
              <input
                type="text"
                class="form-control"
                v-model="form.depositor"
                required
              />
            </div>

            <div class="form-group">
              <label for="">Date Deposited</label>
              <input
                type="date"
                class="form-control"
                v-model="form.deposited_at"
                required
              />
            </div>

            <div class="form-group">
              <label for="">Comments/Remarks</label>
              <textarea
                class="form-control"
                id=""
                cols="30"
                rows="10"
                v-model="form.payment_remarks"
              ></textarea>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button
              id="update-payment-btn"
              type="button"
              class="btn btn-primary"
              @click="update"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- // Edit Modal -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      spinner: false,
      tableLoader: false,
      payments: [],
      app: {
        id: '',
      },
      params: {
        column: 'a.id',
        value: '',
      },
      form: new Form({
        id: '',
        app_no: '',
        firstname: '',
        lastname: '',
        middlename: '',
        control_no: '',
        receipt_no: '',
        receipt_date: '',
        amount: '',
        reference_no: '',
        depositor: '',
        deposited_at: '',
        payment_remarks: '',
      }),
    };
  },
  methods: {
    openModal(payment) {
      $('#edit-payment-details-modal').modal('show');
      this.form.fill(payment);
    },

    clear() {
      this.params.column = '';
      this.params.value = '';
    },
    search() {
      let b = document.getElementById('payment-search-btn');
      b.disabled = true;
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span>`;

      axios
        .post('api/cav-payments/search', this.params)
        .then((res) => {
          this.payments = res.data;
          if (!res.data.length) {
            toastr.error('No results found.');
          }
          b.disabled = false;
          b.innerHTML = `<i class="bi bi-search text-dark"></i>`;
        })
        .catch((err) => {
          toastr.error('Something went wrong.', 'Error');
          b.disabled = false;
          b.innerHTML = `<i class="bi bi-search text-dark"></i>`;
        });
    },

    update() {
      let btn = document.getElementById('update-payment-btn');
      btn.disabled = true;
      btn.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Updating...`;

      axios
        .put('api/cav-payments/' + this.form.id, this.form)
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          btn.disabled = false;
          btn.innerHTML = 'Update';
          this.search();
          $('#edit-payment-details-modal').modal('hide');
        })
        .catch((err) => {
          toastr.error('Something went wrong.', 'Error');
          btn.disabled = false;
          btn.innerHTML = 'Update';
        });
    },
    delete() {},
    changeInputType() {
      this.params.value = '';
      let input = document.getElementById('search-input');
      this.params.column == 'p.receipt_date'
        ? (input.type = 'date')
        : (input.type = 'text');
    },
  },
  mounted() {},
};
</script>
